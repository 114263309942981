<template>
  <g id="accessory/glasses">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M808.09 484.282C798.556 548.771 732.002 555.903 720.177 491.881C722.075 491.05 723.44 489.414 723.155 486.238C723.088 472.286 727.419 457.54 740.005 449.781C753.011 441.775 768.232 440.981 783.283 441.183C812.005 440.499 811.401 464.506 808.09 484.282M709.758 456.235C700.744 455.855 689.34 456.173 681.265 461.665C679.045 455.687 673.277 449.304 667.449 445.891C689.111 446.525 711.409 444.393 733.233 442.738C728.009 446.299 723.149 451.649 719.99 457.834C717.059 456.271 712.861 456.417 709.758 456.235M670.026 491.119C645.031 577.155 582.64 539.655 575.838 528.629C563.569 510.383 561.261 466.029 571.948 454.17C587.246 443.446 608.903 446.206 626.975 445.943C660.176 446.391 683.756 456.323 670.026 491.119M837.603 451.548C830.812 450.66 823.555 450.481 817.241 453.471C815.297 445.208 809.194 438.3 802.303 435.26C801.469 434.214 801.594 433.652 798.745 433.809C781.25 428.551 761.673 431.333 744.861 436.725C718.309 438.961 690.452 440.724 663.677 443.805C638.595 432.541 562.902 430.89 558.198 458.653C552.281 457.768 542.204 453.111 537.232 459.428C500.761 453.245 463.076 461.838 426.447 465.665C417.227 467.363 412.478 485.661 422.944 485.411C428.828 483.814 422.763 474.017 430.686 473.28C466.027 469.42 500.595 463.944 536.298 463.303C535.056 469.468 559.912 469.869 560.279 466.269C560.332 466.296 560.388 466.314 560.442 466.338C554.3 489.228 559.844 522.17 570.944 536.364C586.496 554.95 625.541 560.282 642.854 548.324C665.187 537.287 688.42 481.885 681.894 463.479C693.738 459.725 705.447 460.852 717.739 462.764C714.925 470.713 710.067 490.281 717.056 491.978C715.773 530.828 751.13 555.069 783.724 539.132C811.935 525.889 821.801 483.971 818.349 458.528C821.861 465.314 851.349 457.926 837.603 451.548"
      fill="black"
    />
  </g>
</template>
<script>
export default {
  name: 'AccessoryGlasses'
}
</script>
