<template>
  <g id="facial/moustache2">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M774.528 708.863C776.547 710.778 780.93 714.993 785.129 716.846H789.056C789.899 716.688 790.727 716.447 791.549 716.175C792.618 713.552 792.824 710.496 792.299 707.418C789.331 690.372 786.68 673.111 788.093 655.762C789.424 639.405 794.132 623.128 792.861 606.615C791.088 567.648 753.461 540.665 720.52 548.554C716.267 548.931 712.108 550.128 708.404 552.256C657.018 530.7 593.507 567.122 605.469 626.704C607.762 642.814 611.46 658.093 610.324 674.512C609.78 682.37 608.529 690.142 607.061 697.874C605.959 703.676 603.042 710.83 605.722 716.557C616.488 723.356 627.712 709.226 634.903 697.099C638.667 686.907 641.794 676.472 645.32 666.201C651.682 647.74 653.156 637.544 674.151 632.51C683.219 629.417 691.313 624.857 698.254 618.845C703.589 616.29 706.63 611.172 708.448 605.997C709.354 608.545 708.101 611.638 707.874 614.283C723.101 634.465 741.944 630.644 748.723 644.678C753.91 657.889 756.825 671.973 760.794 685.643C761.337 687.514 771.384 705.881 774.528 708.863"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: 'FacialMoustache2'
}
</script>
