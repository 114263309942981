<template>
  <g id="facial/moustache">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M724.945 551.999C736.659 552.83 747.739 560.098 757.816 565.716C767.514 571.51 774.692 580.095 780.426 589.666L780.798 590.291C781.134 590.79 780.534 591.351 780.042 591.161L779.965 591.124C770.448 586.349 760.406 582.479 751.062 577.27C741.938 571.629 730.734 569.701 721.541 564.57C716.287 560.675 717.864 552.3 724.945 551.999ZM694.476 557.683C694.903 560.355 693.824 564.596 690.316 565.835C684.153 568.009 678.54 569.181 672.35 570.877L664.325 573.125C648.91 577.467 633.264 582.128 619.617 588.968L619.189 588.78C609.276 582.578 664.04 556.704 682.949 552.582C686.423 551.824 693.831 553.651 694.476 557.683Z"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: 'FacialMoustache1'
}
</script>
