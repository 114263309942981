<template>
  <g id="head/mediumstraight">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M837.939 680.959C826.529 627.399 819.21 573.149 812.66 518.829C809.49 492.519 806.96 466.14 804.029 439.81C801.29 415.279 798.099 390.669 791.699 366.789C785.75 344.599 777.049 322.629 764.229 303.47C752.21 285.509 737.099 270.119 719.75 257.269C685.469 231.879 641.629 218.369 598.99 222.849C592.549 223.529 586.169 224.619 579.859 226.079C581.869 225.489 583.9 224.938 585.949 224.459C586.609 224.3 586.316 223.411 585.669 223.47C412.195 239.231 371.389 540.919 367.679 601.749C367.389 606.48 367.16 611.199 366.99 615.929C365.689 626.48 364.719 637.06 364.259 647.669C363.74 659.909 363.99 672.23 365.119 684.429C366.279 696.938 367.599 709.759 372.82 721.259C376.019 728.339 525.219 718.64 526.759 710.279C529.879 693.399 531.479 675.98 530.5 658.81C530.04 650.579 529.189 642.409 527.429 634.349C526.949 632.149 526.439 628.019 525.15 624.749C538.759 598.89 553.729 573.769 569.139 548.939C601.21 497.239 635.309 446.499 661.519 391.438C674.66 363.849 687.609 334.55 693.809 304.319L694.809 304.519C694.689 304.879 694.769 305.329 695.129 305.579C723.619 325.269 742.66 355.109 755 387.06C769.369 424.259 776.339 464.429 775.339 504.289C773.75 567.629 749.74 645.909 681.809 665.929C666.309 670.499 649.57 671.789 633.689 668.449C626.199 666.869 618.859 664.438 612.099 660.839C604.599 656.849 598.439 651.199 591.41 646.55C584.979 642.3 577.389 650.589 581.089 656.879C589.259 670.779 606.839 679.279 621.939 683.459C638.049 687.929 655.04 688.14 671.449 685.3C672.699 685.089 673.939 684.849 675.169 684.589C675.449 688.709 677.219 692.81 680.099 696.14C681.389 697.64 682.979 698.938 684.759 700.029C684.99 704.05 687.58 708.089 691.79 708.97C714.279 713.649 807.679 701.379 830.139 694.688C836.169 692.89 839.219 686.98 837.939 680.959"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M779.277 487.815C777.593 409.234 758.014 322.24 690.899 273.68C686.624 270.583 680.208 272.083 676.665 275.62C673.756 276.188 671.084 277.95 669.307 281.19C629.62 353.625 587.841 424.915 543.989 494.907C515.833 540.163 481.631 585.476 477.119 640.331C476.338 641.368 469.089 649.552 473.313 648.956C475.188 647.975 477.097 647.169 479.105 646.573C484.467 654.39 496.918 653.161 499.878 644.338C510.14 644.633 520.296 647.255 530.073 650.297C554.054 657.803 578.427 665.223 602.901 670.96C683.527 692.358 770.161 665.133 776.603 571.135C785.257 558.942 780.575 504.599 779.277 487.815V487.815Z"
      :fill="skinColor"
    />
  </g>
</template>

<script>
export default {
  name: 'HeadMediumStraight',
  props: {
    skinColor: {
      type: String,
      default () {
        return 'white'
      }
    },
    hairColor: {
      type: String,
      default () {
        return 'black'
      }
    }
  }
}
</script>
