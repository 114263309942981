<template>
  <g id="facial/full">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M612.725 613.193C620.821 594.225 638.492 580.286 658.836 575.189C673.777 571.456 689.456 571.575 704.686 573.057C712.665 573.828 721.224 574.459 728.744 577.422C735.165 579.954 740.398 584.739 744.333 590.356C749.017 597.012 752.202 604.631 754.439 612.439C754.757 613.549 755.056 614.667 755.334 615.791C754.362 621.683 752.296 626.697 746.84 630.401C741.515 634.018 735.161 635.497 728.827 636.023C728.206 635.725 727.606 635.418 727.056 635.104C717.186 629.454 710.239 616.102 697.782 617.909C686.665 619.522 679.346 633.51 672.735 641.713C651.513 644.186 630.307 637.809 613.04 625.674C612.163 621.521 612.113 617.332 612.725 613.193M769.758 598.804C768.06 594.04 765.922 589.426 763.388 585.08C755.009 570.705 742.146 559.252 726.876 552.705C690.754 537.239 644.016 548.141 614.845 573.507C604.88 582.179 594.805 594.921 591.758 608.628C590.848 608.808 589.989 609.281 589.282 609.948C568.206 596.679 546.502 583.912 529.518 565.519C517.185 552.154 508.606 536.339 503.353 518.959C502.924 517.528 500.617 517.738 500.687 519.32C500.707 519.691 500.727 520.06 500.747 520.431C500.527 519.75 499.488 520.1 499.408 520.761C496.582 541.873 493.047 564.257 496.302 585.48C498.25 598.144 504.002 607.414 511.692 617.425C519.931 628.156 529.259 638.136 538.796 647.708C544.848 653.783 551.17 660.791 558.58 665.245C567.198 670.421 576.975 674.356 586.173 678.349C595.531 682.424 605.028 686.188 614.705 689.451C634.049 695.998 654.043 700.523 674.386 702.505C690.674 704.087 709.289 705.81 724.899 699.682C736.863 694.987 746.52 686.499 754.18 676.287C762.519 665.165 769.111 652.497 772.755 639.137C776.542 625.261 770.556 601.042 769.758 598.804"
      fill="black"
    />
  </g>
</template>
<script>
export default {
  name: 'FacialFull'
}
</script>
