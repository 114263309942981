<template>
  <g id="accessory/eyepatch">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M754.396 361.387C760.176 359.406 764.786 365.417 763.676 370.656C762.366 376.826 755.926 381.778 751.656 386.127C747.176 390.707 742.606 395.197 737.866 399.517C728.816 407.778 719.436 415.557 709.546 422.807L706.522 425.023C697.456 431.656 688.417 438.135 678.706 443.877C673.446 446.977 668.136 449.977 662.756 452.867C660.837 453.897 658.496 455.486 656.087 456.667C664.896 463.467 671.676 473.847 673.146 483.517C676.786 507.537 661.306 529.797 639.465 538.647C628.576 543.057 616.456 544.337 604.816 543.007C592.976 541.656 580.976 538.156 573.286 528.457C566.456 519.847 563.996 508.858 563.506 498.067C563.276 492.947 563.326 487.906 564.406 483.377C559.696 483.417 554.986 483.397 550.316 483.447C538.776 483.557 527.215 483.608 515.666 483.287C503.756 482.947 491.906 482.417 480.036 481.358C474.826 480.887 469.646 480.117 464.465 479.377C459.436 478.667 453.886 478.397 449.186 476.337C448.866 476.207 448.999 475.747 449.276 475.656C454.286 474.037 459.976 474.326 465.196 474.007L470.284 473.698C474.522 473.445 478.756 473.212 483.006 473.076C492.824 472.753 502.634 472.674 512.449 472.505L516.656 472.427C528.206 472.197 539.756 472.067 551.306 471.697C556.096 471.547 560.896 471.267 565.706 471.007C566.266 469.736 567.087 468.576 567.876 467.447C570.236 464.067 573.236 461.087 576.346 458.387C582.715 452.826 589.886 448.697 597.886 445.927C605.814 443.191 617.409 441.834 625.796 442.011C634.183 442.187 646.136 443.37 649.976 450.227C650.376 450.937 657.686 449.567 659.856 447.906C664.926 444.037 669.906 440.057 674.795 435.977C683.876 428.387 693.215 420.917 701.816 412.797C710.686 404.417 719.486 395.897 727.926 387.097C732.166 382.687 736.396 378.267 740.506 373.747C744.706 369.137 748.316 363.457 754.396 361.387Z"
      fill="black"
    />
  </g>
</template>
<script>
export default {
  name: 'AccessoryEyePatch'
}
</script>
