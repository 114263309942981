<template>
  <g id="facial/moustache9">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M740.365 550.822C726.339 548.457 711.767 551.953 698.243 555.678C676.76 541.573 648.923 555.81 633.537 573.433C628.271 577.769 617.213 583.53 623.039 591.803C620.831 597.652 620.24 600.404 624.811 593.567C650.679 596.545 678.434 590.919 704.035 585.613C712.752 588.544 720.45 595.397 730.032 593.05C739.093 594.976 748.901 596.775 757.08 590.963C763.308 590.71 777.164 601.894 776.941 588.888C775.015 576.421 750.297 555.678 740.365 550.822Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M750.883 590.845C735.655 592.53 720.212 590.054 707.461 581.212C706.13 580.289 704.818 580.299 703.748 580.84C702.447 580.696 701.116 581.215 700.344 582.701C697.142 588.868 686.529 587.494 680.695 587.62C661.612 587.988 641.863 586.935 623.498 592.704C641.062 558.37 665.353 543.548 702.94 557.027C699.546 559.76 716.482 553.362 717.968 553.24C743.974 546.724 765.429 568.536 775.91 590.15C767.981 588.263 759.096 589.936 750.883 590.845M787.207 596.688C775.74 570.859 753.13 542.459 721.593 546.923C715.118 547.876 708.307 552.299 701.788 551.501C682.913 544.53 667.333 540.988 648.43 552.26C640.41 552.859 595.278 608.736 616.725 604.174C643.051 589.056 688.825 603.296 705.173 589.774C737.268 610.843 763.364 587.956 781.452 601.126C784.597 604.011 789.008 600.418 787.207 596.688"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: 'FacialMoustache9'
}
</script>

<style scoped>

</style>
