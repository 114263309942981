<template>
  <g id="face/goatee2">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M732.595 583.879C735.335 586.689 737.965 589.699 740.445 592.809C741.135 597.749 741.295 602.749 740.665 607.909C739.745 615.389 737.705 622.699 734.945 629.719C733.315 629.489 731.705 629.029 730.135 628.309C725.155 626.019 722.375 621.409 719.005 617.339C712.295 609.249 700.055 604.469 691.495 612.859C687.775 616.509 685.395 621.559 682.075 625.569C678.425 629.979 674.325 634.169 670.045 637.969C669.515 638.449 668.965 638.969 668.395 639.479C666.015 636.759 663.675 634.019 661.385 631.239C654.575 622.959 648.695 613.789 645.605 603.449C644.625 600.169 644.015 596.749 643.775 593.299C646.805 589.539 650.265 586.119 654.195 583.289C666.045 574.739 679.695 573.349 693.895 572.609C708.585 571.849 721.815 572.849 732.595 583.879M768.435 623.549C767.865 617.829 766.985 611.879 765.685 605.939C760.935 583.899 750.645 561.929 730.015 551.859C720.255 547.089 709.165 545.409 698.645 547.709C680.185 540.799 659.685 547.849 645.155 560.489C628.015 575.399 620.105 598.799 617.445 620.719C615.595 635.969 614.898 656.344 619.225 667.409C623.552 678.474 651.051 708.063 690.305 708.269C746.215 708.562 759.805 670.859 761.455 667.559C768.345 653.839 769.945 638.689 768.435 623.549"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: 'FacialGoatee2'
}
</script>
