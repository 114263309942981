<template>
  <svg id="facial/chin">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M704.56 656.064C704.56 656.064 660.17 662.586 640.3 653.368C620.49 644.178 601.22 635.286 583.25 623.113C564.84 610.642 549.22 595.832 538.07 576.875C527.7 559.237 519.08 540.52 510.4 521.948C509.893 520.863 504.91 510.073 503.15 506.455C500.44 500.845 492.52 502.606 492.52 511.315C492.52 520.024 492.13 585.975 507.35 611.918C521.08 635.35 544.35 651.722 567.47 666.012C593.05 681.813 627.03 698.893 657.55 703.406C685.17 707.487 716.69 696.026 736.69 677.136C757.6 657.38 763.52 630.541 774.31 604.839C776.72 599.094 768.176 595.629 765.67 601.279C760.951 611.918 704.56 656.064 704.56 656.064Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'FacialChin'
}
</script>
