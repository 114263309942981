<template>
  <g id="accessory/glasses5v2">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M771.985 527.395C756.977 534.623 736.039 523.962 725.948 512.795C722.275 508.729 719.441 497.096 720.854 489.718C724.681 469.737 739.061 457.745 754.242 453.65C775.286 447.972 796.464 462.356 793.995 479.33C791.654 495.417 788.107 518.752 771.985 527.395M669.834 489.013C668.631 510.788 651.144 529.847 630.244 535.494C596.57 544.89 579.928 521.219 572.39 495.718C571.641 493.184 569.88 485.45 574.605 478.816C593.188 452.721 638.401 457.169 660.983 464.333C667.593 470.654 670.295 479.777 669.834 489.013M788.291 451.456C788.281 451.469 788.269 451.48 788.259 451.493C788.182 451.446 788.106 451.398 788.027 451.352C788.114 451.388 788.206 451.419 788.291 451.456M816.465 453.973C814.509 431.803 744.946 440.292 736.813 442.109C714.617 448.637 695.446 466.397 670.63 458.59C644.917 429.927 558.328 438.147 540.176 452.748C536.74 451.4 532.562 452.123 528.135 453.395C505.401 452.574 401.617 460.411 422.845 489.426C426.067 491.661 429.286 489.387 429.375 484.783C540.038 444.836 529.311 482.253 542.435 477.883C544.439 478.46 546.53 478.825 548.359 479.209C558.517 482.477 561.966 481.467 563.304 492.87C572.497 580.921 689.752 550.531 680.135 478.307C689.657 477.783 697.986 473.666 708.055 477.686C715.046 545.723 769.797 548.469 786.274 524.884C798.699 508.514 793.772 483.81 810.851 470.932C817.593 476.574 818.027 457.532 816.465 453.973"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: 'AccessoryGlasses5v2'
}
</script>

<style scoped>

</style>
