<template>
  <g id="facial/moustache3">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M845.983 501C843.863 497.81 837.853 499.75 839.403 503.77C856.226 541.942 848.443 571.632 801.162 566.439C772.658 564.588 744.976 539.383 716.349 551.925C708.968 557.854 704.109 558.589 696.31 552.173C665.364 538.632 630.324 562.77 599.233 566.03C550.588 570.813 524.131 554.539 548.704 504.64C550.243 501.25 545.773 496.78 543.033 500.26C509.102 539.743 507.398 592.756 562.015 613.21C602.1 626.558 678.759 637.603 706.273 597.36C723.835 626.42 761.37 628.159 791.544 622.959C864.4 616.234 886.724 559.626 845.983 501"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: 'FacialMoustache3'
}
</script>
