<template>
  <g id="accessory/glass5">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M786.09 495.432C783.262 503.128 778.958 511.14 773.695 517.617C752.401 535.649 731.71 525.665 721.204 501.226C717.212 483.955 727.811 465.351 739.042 452.646C755.262 436.389 781.733 444.921 789.191 464.247C789.644 466.751 789.918 469.249 790.049 471.739C789.894 479.83 788.919 486.745 786.09 494.432M581.41 479.911C609.462 446.544 661.942 444.76 660.603 496.115C654.577 561.119 553.106 545.164 581.41 479.911ZM794.913 452.076C793.93 450.411 792.839 448.8 791.648 447.259C791.208 446.997 790.955 444.673 789.337 444.501C767.71 420.642 729.717 434.179 715.989 457.953C713.482 460.959 711.437 464.478 709.929 468.196C696.403 465.715 680.634 466.197 668.054 471.306C655.58 438.734 617.815 435.826 592.928 449.743C577.449 457.356 567.412 473.299 552.358 482.057C516.969 484.604 478.988 476.675 444.238 485.552C416.832 491.25 439.742 505.127 444.338 495.866C477.101 486.186 515.916 494.366 549.933 492.185C553.47 498.168 560.253 503.064 567.013 502.855C574.314 567.576 668.698 556.844 671.954 499.311C675.647 493.472 680.24 487.884 686.814 484.246C689.331 483.725 691.762 483.048 693.984 482C697.782 481.464 703.634 483.074 707.128 486.236C719.097 558.052 788.052 549.688 800.375 486.774C800.62 486.524 801.305 485.586 801.831 484.081C806.049 478.504 816.375 476.431 814.725 467.016C813.256 457.599 801.364 457.711 794.913 452.076Z"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: 'AccessoryGlasses5'
}
</script>
