<template>
  <g id="facial/moustace8">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1015.33 459.494C999.335 474.087 1009.61 510.645 1015.85 500.762C1016.78 491.61 1019.64 474.074 1028.92 470.526C1097.15 467.616 1083.06 636.321 955.957 615.557C874.977 610.093 789.446 492.817 713.294 564.185C630.554 496.788 537.127 597.828 451.924 611.666C382.649 634.069 279.125 592.931 297.768 507.53C302.425 482.47 337.081 453.803 358.306 479.235C364.576 485.276 356.611 507.401 363.203 502.827C373.465 491.49 370.791 473.385 361.307 462.094C345.599 443.396 317.599 451.576 301.311 464.781C227.828 537.072 316.334 646.195 404.912 638.986C501.978 650.858 633.98 515.962 700.701 581.494C707.505 588.176 720.29 588.179 726.205 581.499C782.929 517.445 892.404 648.357 971.481 639.008C1055.58 646.668 1122.89 545.163 1074.35 473.394C1062.26 455.148 1033.19 441.396 1015.33 459.494Z"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: 'FacialMoustache8'
}
</script>

<style scoped>

</style>
