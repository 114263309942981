<template>
  <g id="facial/moustache5">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M789.392 550.722C788.059 546.74 781.2 547.087 781.622 551.774C782.105 557.129 780.272 563.101 775.142 565.701C769.77 568.423 763.726 565.976 760.449 561.306C757.6 557.247 755.635 552.832 752.05 549.292C748.717 546 744.663 543.461 740.228 541.954C736.774 540.781 733.191 540.469 729.716 540.854C721.056 538.897 711.022 543.348 705.625 550.1C705.277 550.535 704.954 550.982 704.642 551.433C704.107 550.705 703.547 549.984 702.954 549.273C701.425 547.441 699.527 547.048 697.813 547.547C688.052 540.288 674.373 539.326 663.287 545.877C654.405 551.126 647.058 558.862 636.136 559.464C627.485 559.941 618.062 555.005 617.452 545.498C617.132 540.534 610.368 536.396 606.573 540.992C598.595 550.656 596.999 563.493 601.413 575.178C606.071 587.507 615.561 596.192 627.577 601.301C640.553 606.818 655.759 608.734 669.796 608.297C683.79 607.861 696.514 602.48 704.949 591.033C705.956 589.667 706.841 588.279 707.617 586.875C715.807 597.303 728.645 601.686 741.721 602.001C755.401 602.329 771.573 599.928 781.245 589.304C790.566 579.064 793.783 563.836 789.392 550.722"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: 'FacialMoustache5'
}
</script>
