<template>
  <g id="facial/goatee1">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M702.914 549.328C691.793 544.319 671.823 548.69 664.573 550.428C647.718 554.47 630.762 563.205 625.522 576.89C619.617 592.313 631.399 597.168 633.132 596.375C644.8 597.197 658.695 589.862 670.922 587.969C680.848 586.419 691.319 585.066 700.619 579.792C707.409 583.527 722.745 585.546 735.122 588.812C745.663 591.592 750.23 597.621 760.002 596.339C771.714 594.803 766.92 570.17 753.867 560.191C739.581 549.27 715.419 544.841 702.914 549.328ZM724.934 654.653C715.205 657.025 714.551 635.7 713.461 628.166C712.61 622.28 707.807 607.101 695.631 607.756C683.455 608.411 678.879 621.128 679.496 629.038C679.897 634.167 684.623 660.149 669.536 663.436C659.917 663.402 648.789 662.753 638.675 660.888C626.771 658.192 611.292 648.753 607.203 667.342C605.732 674.032 658.169 693.909 681.438 695.707C705.037 700.489 732.647 693.136 749.448 675.083C753.27 670.976 764.036 653.93 764.803 649.753C766.415 640.978 762.683 635.331 751.825 638.424C744.155 642.52 731.191 653.128 724.934 654.653Z"
      fill="black"
    />
  </g>
</template>
<script>
export default {
  name: 'FacialGoatee1'
}
</script>
