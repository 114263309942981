<template>
  <svg id="facial/moustache6">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M753.53 555.24C744.479 551.008 734.751 548.489 724.757 547.596C720.435 547.209 711.945 545.988 707.348 548.807C705.198 550.127 703.381 551.971 701.94 554.223C701.378 553.446 700.638 552.784 700.17 551.944C697.653 547.422 682.205 547.328 677.541 547.712C666.481 548.622 655.716 551.219 645.578 555.585C627.377 563.425 612.087 579.714 616.895 600.394C617.575 603.323 620.856 604.462 623.559 603.956C641.778 600.541 659.997 597.125 678.217 593.71C682.446 592.918 686.676 592.125 690.905 591.332C694.73 590.615 699.418 590.388 703.048 588.738C704.508 589.362 706.134 589.758 707.87 590.102C729.791 594.456 751.74 598.68 773.675 602.969C776.305 603.484 779.755 602.15 780.227 599.236C783.375 579.792 770.992 563.404 753.53 555.24"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'FacialMoustache6'
}
</script>
