<template>
  <g id="facial/moustache7">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M753.635 556.664C747.041 555.425 740.224 555.773 733.674 554.331C727.284 552.923 722.209 548.736 716.035 546.787C710.602 545.071 704.234 546.233 700.003 549.559C696.072 547.742 691.605 547.349 687.133 547.949C680.351 548.859 674.023 551.729 667.982 554.833C667.282 555.193 666.581 555.536 665.881 555.883C654.719 555.604 643.276 555.626 632.323 558.018C622.624 560.137 613.455 565.069 607.671 573.307C601.198 582.528 600.029 594.27 599.031 605.166C598.396 612.082 608.002 613.485 611.257 608.477C614.632 603.285 618.145 598.081 622.642 593.779C627.75 588.892 633.963 586.968 640.926 586.522C656.294 585.538 671.764 586.363 687.147 585.682C696.064 585.287 705.992 581.858 709.586 574.106C713.422 580.117 722.114 583.691 729.263 584.233C736.818 584.807 744.489 583.434 751.969 585.008C759.115 586.512 767.609 591.834 766.02 600.346C765.086 605.346 771.407 608.294 774.316 603.844C786.195 585.661 774.49 560.585 753.635 556.664"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: 'FacialMoustache7'
}
</script>

<style scoped>

</style>
