<template>
  <g id="accessory/sunglasses2">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M817.935 434.231C817.112 434.094 816.288 433.965 815.464 433.844C814.821 433.479 814.066 433.198 813.188 433.029C811.183 432.52 809.192 432.177 807.204 431.992C778.672 425.571 712.665 434.688 709.941 459.036C700.855 462.069 692.993 459.353 682.462 459.623C674.446 426.118 535.863 430.847 529.198 451.047C529.173 451.04 529.148 451.034 529.122 451.026C522.718 449.044 513.277 449.522 505.947 449.208C490.296 448.88 474.627 450.275 459.314 453.576C430.639 458.133 391.504 463.468 411.447 499.276C411.451 499.282 411.454 499.289 411.459 499.295L411.462 499.292C414.899 502.815 420.732 499.978 420.949 495.209C414.244 478.245 429.388 476.208 441.04 471.333C470.033 460.917 499.551 459.473 529.537 462.695C531.793 465.351 535.216 467.667 538.834 468.879C558.883 472.207 548.317 487.079 560.99 489.242C558.662 541.084 621.603 569.382 658.072 535.303C672.633 521.067 676.104 499.978 679.48 480.969C681.265 480.568 682.4 479.592 683.063 478.3C687.356 474.014 690.053 470.056 698.542 468.879C707.031 467.703 713.566 470.064 714.45 470.787C714.673 471.236 714.912 471.686 715.19 472.136C715.578 472.807 716.106 473.342 716.706 473.763C708.323 525.387 748.4 558.035 788.859 531.26C806.439 514.723 810.154 489.405 810.888 466.807C813.271 465.369 814.78 462.582 813.955 459.078C812.818 454.248 815.484 450.066 819.73 447.966C825.247 445.239 824.271 435.28 817.935 434.231M644.087 458.722C639.065 457.618 636.92 465.361 641.958 466.468C645.526 467.253 648.549 469.052 650.592 472.099L650.807 472.431C653.548 476.793 660.48 472.766 657.72 468.376C654.614 463.436 649.788 459.976 644.087 458.722ZM791.173 453.922C789.403 452.675 786.724 453.6 785.696 455.363C784.565 457.303 785.306 459.464 786.964 460.733L787.133 460.857C788.654 461.929 790.135 463.851 790.321 465.819L790.338 466.066C790.537 471.218 798.544 471.244 798.344 466.066C798.149 461 795.215 456.767 791.173 453.922Z"
      fill="black"
    />
  </g>
</template>
<script>
export default {
  name: 'AccessorySunGlasses2'
}
</script>
