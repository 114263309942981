<template>
  <g id="accessory/glasses2">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M791.311 516.458C779.17 529.003 758.902 532.603 742.749 525.875C701.273 508.546 721.574 454.496 753.561 439.981C757.557 438.985 762.894 436.622 767.761 436.086C768.201 435.87 769.159 436.495 770.32 435.904C805.591 439.842 813.623 494.594 791.311 516.458M657.533 519.669C643.916 537.73 618.6 543.302 597.916 534.906C557.054 518.814 571.547 470.885 599.504 452.095C658.561 429.904 682.948 486.368 657.533 519.669M568.953 479.651C568.915 479.633 568.877 479.624 568.838 479.606C569.054 479.511 569.27 479.416 569.485 479.319C569.415 479.502 569.339 479.683 569.271 479.866C569.165 479.793 569.072 479.708 568.953 479.651M819.423 460.128C816.089 460.135 811.273 460.683 808.513 462.996C801.707 440.043 776.585 418.8 754.737 434.29C737.532 439.823 724.765 452.094 717.509 468.408C704.426 460.626 683.854 463.949 672.509 474.24C665.079 449.689 646.793 441.092 625.028 439.823C601.272 435.932 577.594 459.003 570.206 477.511C564.063 476.812 554.884 474.857 549.105 478.648C511.598 468.812 446.023 463.389 438.398 468.854C432.964 470.939 429.982 483.765 437.572 484.457C444.186 483.518 436.8 475.059 443.467 474.136C478.99 471.934 513.475 479.088 548.622 482.09C551.76 486.311 561.899 487.723 567.308 486.027C558.789 517.423 577.179 538.783 604.734 545.181C647.401 554.982 684.522 516.814 673.365 477.114C686.29 470.684 701.157 470.96 715.237 474.347C691.709 560.937 831.166 557.052 809.385 465.963C823.88 473.173 827.028 461.583 819.423 460.128"
      fill="black"
    />
  </g>
</template>
<script>
export default {
  name: 'AccessoryGlasses2'
}
</script>
