<template>
  <g id="facial/moustache4">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M800.269 531.004C796.955 526.555 790.998 520.716 784.972 521.03C784.201 521.07 783.912 521.905 784.375 522.47C786.346 524.877 789.039 526.589 791.014 529.046C793.351 531.954 795.038 535.461 796.135 539.008C797.729 544.161 798.169 549.993 796.748 555.233C794.244 564.469 786.327 571.239 777.689 574.696C769.139 578.118 758.851 579.287 750.765 574.273C738.83 566.871 728.707 556.201 713.706 555.99C710.197 555.941 707.724 557.548 706.251 559.842C704.771 557.63 702.329 556.065 698.908 556.026C682.091 555.837 669.539 567.414 655.424 574.631C645.885 579.509 633.542 577.995 623.701 574.772C614.418 571.733 604.341 565.63 600.839 555.993C598.906 550.671 599.339 544.457 601.324 539.211C602.629 535.761 604.644 532.428 607.137 529.717C609.722 526.905 613.12 525.137 615.768 522.471C616.309 521.926 615.882 521.079 615.171 521.03C603.925 520.267 593.77 533.695 591.647 543.437C589.556 553.031 592.674 562.6 598.985 569.921C611.818 584.805 635.58 589.714 654.074 585.34C669.043 581.801 683.26 573.649 698.908 574.819C702.338 575.076 704.8 573.646 706.286 571.478C707.774 573.709 710.252 575.168 713.706 574.834C728.29 573.421 741.307 583.749 755.463 586.271C766.734 588.278 778.909 585.571 788.567 579.499C798.662 573.152 805.768 562.971 806.236 550.833C806.505 543.85 804.456 536.625 800.269 531.004"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: 'FacialMoustache4'
}
</script>

<style scoped>

</style>
