<template>
  <g id="facial/full3">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M722.994 622.31C713.681 614.013 703.242 597.333 689.863 610.531C686.554 614.26 685.214 622.62 680.154 624.26C673.434 626.44 666.774 619.33 664.484 613.87C655.114 583.533 693.848 575.212 715.914 580.113C726.694 584.54 734.634 602.36 735.114 611.66C735.424 617.91 730.134 627.1 722.994 622.31M793.254 575.55C793.163 575.147 793.036 574.783 792.883 574.452C788.764 572.895 785.505 574.483 783.491 577.449C783.557 578.186 783.777 578.933 783.916 579.664C782.362 582.938 780.96 586.989 778.854 589.818C778.438 589.85 778.007 589.806 777.593 589.686C742.68 593.332 759.725 556.803 724.314 547.73C696.941 543.214 647.467 548.387 634.554 576.878C624.37 622.386 572.587 590.676 555.585 567.009C537.444 545.47 525.714 519.15 514.404 493.61C512.014 488.205 504.555 492.07 505.366 497.256C494.912 518.227 495.26 543.417 492.671 566.391C488.267 612.226 483.976 676.504 526.563 704.491C582.05 767.208 720.608 789.34 784.606 731.714C806.76 684.084 804.029 625.977 793.254 575.55"
      fill="black"
    />
  </g>
</template>
<script>
export default {
  name: 'FacialFull3'
}
</script>
